import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import StoryblokReact from 'storyblok-react';
import StoryblokClient, { Story } from 'storyblok-js-client';
import { getComponent, blokToComponent } from '../components';
import {
  DomService, StoryblokService, LanguageService,
} from '../services';
import { EntryData, StoryDataFromGraphQLQuery } from '../templates/default';

type StoryblokEntryState = EntryData;
const searchModal = typeof document !== 'undefined' ? document.getElementById('global-modal') : null;
const RocheGlobalConfig = getComponent('roche-global-config') as React.ReactType;
const Header = 'roche-header' as React.ReactType;
const OffCanvas = 'roche-offcanvas-panel' as React.ReactType;
const Search = 'roche-search' as React.ReactType;
const MobileNavigation = 'roche-mobile-navigation' as React.ReactType;
const RocheAnimationManager = 'roche-animation-manager' as React.ReactType;

const loadStoryblokBridge = (onLoadHandler: EventListener): void => {
  const script = DomService.createElement('script', '', {
    src: `//app.storyblok.com/f/storyblok-latest.js?t=${StoryblokService.getConfig().options.accessToken}`,
  });
  script.onload = onLoadHandler;
  document.head.appendChild(script);
};

// eslint-disable-next-line import/no-default-export
export default class StoryblokEntry extends Component<object, StoryblokEntryState> {
  private storyblokClient: StoryblokClient;

  public constructor(props: object) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleStoryblokLoad = this.handleStoryblokLoad.bind(this);
    this.loadStory = this.loadStory.bind(this);
    this.handleSearchModalTrigger = this.handleSearchModalTrigger.bind(this);
    this.handleCloseSearchModalTrigger = this.handleCloseSearchModalTrigger.bind(this);

    this.state = {} as EntryData;
  }

  /*
   * While editing, mismatches between the "real" DOM and React's virtual DOM might happen.
   * When errors like these occur React DOM unmounts, resulting in a blank page.
   *
   * Slider children, are especially prone to this exception upon deletion.
   * This is caused by the 'input' event handling.
   * To minimise editor frustration we handle these using error boundaries.
   *
   * More info:
   * https://reactjs.org/docs/reconciliation.html
   * https://reactjs.org/docs/error-boundaries.html
   */
  static getDerivedStateFromError(): { hasError: true } {
    return { hasError: true };
  }

  public async componentDidMount(): Promise<void> {
    this.storyblokClient = new StoryblokClient({
      accessToken: StoryblokService.getConfig().options.accessToken as string,
    });
    loadStoryblokBridge(this.handleStoryblokLoad);
    await this.loadSettings();

    window.addEventListener('rocheLoginComplete', this.handleLogin);
    window.addEventListener('rocheModalTrigger', this.handleSearchModalTrigger);
    window.addEventListener('rocheModalClose', this.handleCloseSearchModalTrigger);
    this.setState({ initAOS: true });

    if (!StoryblokService.getObject()?.isInEditor()) {
      DomService.activateConsentScript();
    }
  }

  public render(): JSX.Element {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong!</h1>
          <p>Your work is not lost, but you will not see any new changes changes until you save.</p>
          <button
            onClick={(): void => window.location.reload()}
          >
            Refresh editor view and hide this error.
          </button>
        </div>
      );
    }

    const {
      story,
      footer,
      onClickNotice,
      search,
      languages,
      navigation,
      initAOS,
      ...globalConfig
    } = this.state;

    if (!story || !story.content) {
      return <div></div>;
    }

    const canShowFooter = !story.content.hide_footer;

    return (
      <StoryblokReact content={story.content}>
        <RocheGlobalConfig
          {...globalConfig}
          tagPageUrl={`${window.location.origin}${this.state.getTagPageUrl}`}
        />

        <OffCanvas id="roche-offcanvas-menu">
          <MobileNavigation
            languages={JSON.stringify(languages)}
          >
            {!story.content.hide_navigation
            && navigation
            && blokToComponent({ blok: navigation?.content, getComponent })}
          </MobileNavigation>
        </OffCanvas>

        {this.state.isSearchActive
          ? ReactDOM.createPortal(<Search
            id="roche-search"
            total-results-for-query={search?.content?.total_results_for_query}
          />, searchModal)
          : null
        }

        <Header
          languages={JSON.stringify(languages)}
          hide-language-switch={story.content.hide_language_switch}
        >
          {!story.content.hide_navigation
          && navigation
          && blokToComponent({ blok: navigation?.content, getComponent })}
        </Header>
        <RocheAnimationManager can-initialize={initAOS}>
          {blokToComponent({ blok: story.content, getComponent, storyId: story.id })}
        </RocheAnimationManager>
        {canShowFooter && footer && blokToComponent({ blok: footer?.content, getComponent })}
        {onClickNotice && blokToComponent({ blok: onClickNotice.content, getComponent })}
      </StoryblokReact>
    );
  }

  private handleSearchModalTrigger(event): void {
    if (event.detail === 'roche-search') {
      this.setState({ isSearchActive: true });
    }
  }

  private handleCloseSearchModalTrigger(): void {
    this.setState({ isSearchActive: false });
  }

  private handleStoryblokLoad(): void {
    this.loadStory();
    const storyblok = StoryblokService.getObject();

    if (storyblok) {
      storyblok.on(['change', 'published'], () => window.location.reload());

      storyblok.on('input', (data: Story['data']) => {
        const { story: currentStory } = this.state;
        const story = data?.story as StoryDataFromGraphQLQuery;

        if (currentStory?.id === story?.id) {
          story.content = storyblok.addComments(story.content, story.id);
          this.setState({
            story,
            ...DomService.getGlobalConfig(story, this.state.settings),
          });
        }
      });

      storyblok.pingEditor(() => {
        if (storyblok.inEditor) {
          storyblok.enterEditmode();
        }
      });
    }
  }

  private handleLogin(): void {
    StoryblokService.redirect(({ story }) => {
      this.setState({
        story,
        ...DomService.getGlobalConfig(story, this.state.settings),
      });
    });
  }

  private loadStory(): void {
    const storyblok = StoryblokService.getObject();
    const storyblokConfig = StoryblokService.getConfig();

    if (storyblok && storyblokConfig) {
      const currentPath = storyblok.getParam('path');
      storyblok.get(
        {
          slug: currentPath === '/' ? '/home' : currentPath,
          version: 'draft',
          /* eslint-disable @typescript-eslint/camelcase */
          resolve_relations: storyblokConfig.options.resolveRelations || [],
          resolve_links: storyblokConfig.options.resolveLinks || '',
          /* eslint-enable @typescript-eslint/camelcase */
        },
        ({ story }) => {
          this.setState({
            story,
            ...DomService.getGlobalConfig(story, this.state.settings),
          });
          this.loadNavigation(story.lang);
          this.loadFooter(story.lang);
          this.loadOnclickNotice(story.lang);
          this.loadLanguages();
          this.loadSearch(story.lang);
        },
      );
    }
  }

  private async loadNavigation(lang?: string): Promise<void> {
    /* eslint-disable @typescript-eslint/camelcase */
    const queryOptions = {
      filter_query: {
        component: {
          in: 'navigation',
        },
      },
      ...(lang !== 'default' && { starts_with: `${lang}/*` }),
    };
    /* eslint-enable @typescript-eslint/camelcase */

    const navigation = await this.storyblokClient.getAll('cdn/stories', queryOptions);
    this.setState({ navigation: navigation[0] });
  }

  private async loadFooter(lang?: string): Promise<void> {
    /* eslint-disable @typescript-eslint/camelcase */
    const queryOptions = {
      filter_query: {
        component: {
          in: 'roche-footer',
        },
      },
      ...(lang !== 'default' && { starts_with: `${lang}/*` }),
    };
    /* eslint-enable @typescript-eslint/camelcase */

    const footer = await this.storyblokClient.getAll('cdn/stories', queryOptions);
    this.setState({ footer: footer[0] });
  }

  private async loadSettings(): Promise<void> {
    const queryOptions = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      filter_query: {
        component: {
          in: 'settings',
        },
      },
    };

    const settings = await this.storyblokClient.getAll('cdn/stories', queryOptions);
    this.setState({ settings: settings[0] });
  }

  private async loadSearch(lang?: string): Promise<void> {
    /* eslint-disable @typescript-eslint/camelcase */
    const queryOptions = {
      filter_query: {
        component: {
          in: 'roche-search',
        },
      },
      ...(lang !== 'default' && { starts_with: `${lang}/*` }),
    };
    /* eslint-enable @typescript-eslint/camelcase */

    const search = await this.storyblokClient.getAll('cdn/stories', queryOptions);
    this.setState({ search: search[0] });
  }

  private async loadOnclickNotice(lang?: string): Promise<void> {
    const slugWithLang = lang !== 'default' ? `/${lang}/on-click-notice` : 'on-click-notice';
    const { data } = await this.storyblokClient.getStory(slugWithLang);
    this.setState({ onClickNotice: data.story });
  }

  private async loadLanguages(): Promise<void> {
    const languages = await LanguageService.getLanguages();
    this.setState({ languages });
  }
}
